.container {
  display: flex;
}

.side-area {
  padding: var(--dj-size-spacing-xl) var(--dj-size-spacing-2xl);
  width: 300px;
  flex-shrink: 0;
  /* background-color: red; */
}
.main-area {
  /* background-color: blue; */
  padding: var(--dj-size-spacing-xl) var(--dj-size-spacing-2xl);
  flex-grow: 1;
}

.flex-row {
  display: flex;
  gap: 10px;
  align-items: center;
}

label {
  display: block;
  font-weight: bold;
}
textarea {
  width: 100%;
  min-height: 100px;
  padding: var(--dj-size-spacing-sm) var(--dj-size-spacing-sm);
  box-shadow: var(--dj-shadow-sm);
  border: none;
  resize: vertical;
}
table {
  width: 100%;
  text-align: left;
}
thead {
  box-shadow: var(--dj-shadow-sm);
  background-color: var(--dj-color-card-background);
}
tr:nth-child(even) {
  background: var(--dj-color-card-background);
}
tr:nth-child(odd) {
  background: var(--dj-color-card-background);
}
td,
th {
  padding: var(--dj-size-spacing-sm) var(--dj-size-spacing-sm);
}
.pointer {
  cursor: pointer;
}

.hero-split {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hero-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--dj-size-spacing-xs);
}

.danger-banner {
  background-color: var(--dj-color-danger);
  color: var(--dj-color-danger-text);
  padding: var(--dj-size-spacing-sm) var(--dj-size-spacing-2xl);

  display: block;
  box-shadow: var(--dj-shadow-sm);
}

.banner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error {
  color: var(--dj-color-danger);
}
.damage-button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.damage-button {
  min-width: 30%;
  font-size: var(---dj-size-font-3xl) !important;
}
.barcode {
  height: 120px;
}
.button-xs {
  padding: var(--dj-size-spacing-2xs) var(--dj-size-spacing-sm) !important;
}

.side-card {
  width: 100%;
  margin-bottom: var(--dj-size-spacing-md);
}
.side-card ol {
  padding-left: var(--dj-size-spacing-md);
}
.side-card button {
  width: 100%;
}

.side-button {
  width: 100%;
  margin-bottom: var(--dj-size-spacing-md);
  display: flex;
  align-items: center;
  justify-content: left;
  gap: var(--dj-size-spacing-sm);
}
