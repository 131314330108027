.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.468);
}

.modal {
  position: fixed;
  top: 10vh;
  left: 10vw;
  right: 10vw;
  min-height: 10vh;
  background-color: var(--dj-color-card-background);
  padding: var(--dj-size-spacing-lg) var(--dj-size-spacing-xl) 70px;
  box-shadow: var(--dj-shadow-sm);
}

.modal-close {
  position: absolute;
  right: 10px;
  top: 5px;
  padding: var(--dj-size-spacing-sm) var(--dj-size-spacing-sm);
  font-weight: 900;
  cursor: pointer;
}

.input-with-button-container {
  padding: var(--dj-size-spacing-sm) 0;
  display: flex;
}
